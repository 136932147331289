import React from 'react'
import {
  FormBuilderSelectAddGroupToOptions,
  FormBuilderSelectGetValue,
  Select,
} from '#/components/FormControl/Fields/Select'

function FormBuilderSelectSync(props) {
  const options = FormBuilderSelectAddGroupToOptions(props.options)

  return (
    <Select
      {...props}
      value={FormBuilderSelectGetValue(options, props.value, props.multi)}
      options={options}
      multiple={props.multi}
    />
  )
}

export { FormBuilderSelectSync }
