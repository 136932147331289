import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'

const DEBUG = import.meta.env.VITE_DEBUG === 'true'

// Laravel Broadcasting Documentation
// https://laravel.com/docs/10.x/broadcasting#client-side-installation
// https://blog.logrocket.com/how-to-set-up-laravel-websockets-subdomain/

export function connect() {
  window.Pusher = Pusher

  window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_WEBSOCKET_KEY,
    wsHost: import.meta.env.VITE_WEBSOCKET_DOMAIN,
    wssPort: 6001,
    disableStats: true,
    encrypted: true,
    forceTLS: true,
    enabledTransports: ['wss', 'ws'],
    authorizer: channel => {
      return {
        authorize: (socketId, callback) => {
          fetcher({
            method: 'post',
            controller: endpoint.websocket.authorize(),
            params: {
              socket_id: socketId,
              channel_name: channel.name,
            },
          })
            .then(response => {
              callback(null, response?.data)
            })
            .catch(error => {
              callback(error)
            })
        },
      }
    },
  })
}

export function subscribe(channel, event, cb, notPrivate) {
  if (notPrivate) {
    window.Echo.channel(channel).listen(event, cb)
  } else {
    const pusherChannel = window.Echo.private(channel).listen(event, cb)
    DEBUG && console.info('Connected to:', pusherChannel.name)
  }
  return () => unsubscribe(channel)
}

export function unsubscribe(channel) {
  window.Echo.leave(channel)
  DEBUG && console.info('Disconnected to:', channel)
}

export function leaveAll() {
  window.Echo.leaveAll()
}

export function disconnect() {
  window.Echo.disconnect()
}

const ws = { connect, disconnect, subscribe, unsubscribe, leaveAll }

export default ws
