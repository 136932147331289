/* prettier-ignore */
const eventCode = {
  reproduction: {
    abortion:                                     'reproduction.abortion',
    apt_after_delivery:                           'reproduction.apt_after_delivery',
    calving:                                      'reproduction.birth',
    dry:                                          'reproduction.dry',
    embryo_transfer:                              'reproduction.embryo_transfer',
    empty:                                        'reproduction.empty',
    heat:                                         'reproduction.heat',
    visual_heat:                                  'reproduction.visual_heat',
    heifer:                                       'reproduction.heifer',
    iatf:                                         'reproduction.ftai',
    implant_insertion:                            'reproduction.implant_insertion',
    implant_removal:                              'reproduction.implant_removal',
    inapt_after_delivery:                         'reproduction.inapt_after_delivery',
    induced_lactation:                            'reproduction.induced_lactation',
    insemination:                                 'reproduction.inseminated',
    natural_breeding:                             'reproduction.natural_breeding',
    late_inseminated:                             'reproduction.late_inseminated',
    negative_pregnant:                            'reproduction.negative_pregnancy',
    pregnant:                                     'reproduction.pregnant',
    pregnant_pre_delivery:                        'reproduction.pregnant_pre_delivery',
    weaning:                                      'reproduction.weaning',
    gynecologic_exam_inapt:                       'reproduction.gynecologic_exam_inapt',
    gynecologic_exam_apt:                         'reproduction.gynecologic_exam_apt',
    reproduction_protocol_heat_observation_end:   'reproduction.protocol.heat_observation_end',
    reproduction_protocol_heat_observation_start: 'reproduction.protocol.heat_observation_start',
    reproduction_protocol_sync_start:             'reproduction.protocol.sync_start',
    reproduction_protocol_animal_removed:         'reproduction.protocol.animal_removed',
  },
  health: {
    condition:                                     'health.condition',
    condition_confirmation:                        'health.condition_confirmation',
  },
  cattle_handling: {
    general:                                       'handling.general',
    nutrition:                                     'handling.nutrition',
    reproduction:                                  'handling.reproduction',
    sanitary:                                      'handling.sanitary',
  },
}

export default eventCode
