import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Box, IconButton, Popover, Stack, TextField } from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import ColorizeIcon from '@mui/icons-material/Colorize'

import { presetColors } from '#/components/FormControl/Fields/Color/preset'
import { contrastColor } from '#/utils/contrastColor'

// Componente estilizado para o botão circular de cores
const ColorButton = styled(Box)(({ color, selected }) => ({
  'minWidth': '25px',
  'minHeight': '25px',
  'cursor': 'pointer',
  'borderRadius': '8px',
  'transition': 'box-shadow 100ms, transform 100ms',
  'boxShadow': `${color} 0px 0px 0px 14px inset`,
  '&:hover': {
    transform: 'scale(1.2)',
  },
  ...(selected && {
    boxShadow: `${color} 0px 0px 0px 3px inset`,
  }),
}))

// Componente estilizado para a pilha de botões de cores
const ColorButtonStack = styled(Stack)({
  flexDirection: 'row',
  gap: '4px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  minWidth: 130,
  maxWidth: 200,
})

// Componente para exibir as cores pré-definidas em forma de botões circulares
const PresetColors = ({
  colors,
  maxItems,
  onSelectColor,
  selectedColor,
  style,
}) => {
  const displayedColors = colors.slice(0, maxItems)

  return (
    <ColorButtonStack style={style}>
      {displayedColors.map(color => (
        <ColorButton
          key={color}
          color={color}
          onClick={() => onSelectColor(color)}
          selected={selectedColor === color}
        />
      ))}
    </ColorButtonStack>
  )
}

function FormBuilderColor({ value, error, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [color, setColor] = React.useState('#000')

  const open = Boolean(anchorEl)
  const popoverId = open ? 'color-picker-popover' : undefined

  // Abre o seletor de cores
  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  // Fecha o seletor de cores
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Atualiza a cor selecionada
  const handleChangeColor = newColor => {
    setColor(newColor)
    if (newColor) {
      props.onChange(newColor)
    }
  }

  // Gera as cores pré-definidas de forma embaralhada
  const shuffledColors = useMemo(() => {
    const randomColors = presetColors.sort(() => Math.random() - 0.5)
    setColor(value ?? randomColors[0]) // Define a cor inicial
    if (!value) {
      props.form?.setValue(props.name, randomColors[0])
    }
    return randomColors
  }, [])

  // Popover que contém o seletor de cores e os botões de cores pré-definidas
  const ColorPickerPopover = (
    <Popover
      id={popoverId}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      slotProps={{
        paper: { sx: { padding: 2 } },
      }}
    >
      <HexColorPicker color={color} onChange={handleChangeColor} />
      <PresetColors
        colors={shuffledColors}
        maxItems={21}
        onSelectColor={handleChangeColor}
        selectedColor={color}
        style={{ marginTop: 16 }}
      />
    </Popover>
  )

  return (
    <Box>
      <TextField
        fullWidth
        error={Boolean(error)}
        helperText={error}
        value={color}
        label={props.label}
        disabled={props.disabled}
        onBlur={props.onBlur}
        required={props.required}
        onChange={event => handleChangeColor(event.target.value)}
        slotProps={{
          input: {
            startAdornment: (
              <IconButton
                size="small"
                aria-describedby={popoverId}
                onClick={handleOpen}
                style={{
                  backgroundColor: color,
                  color: contrastColor(color),
                  marginRight: 10,
                }}
              >
                <ColorizeIcon />
              </IconButton>
            ),
            endAdornment: (
              <PresetColors
                colors={shuffledColors}
                maxItems={5}
                onSelectColor={handleChangeColor}
                selectedColor={color}
                style={{ justifyContent: 'end', flexWrap: 'nowrap' }}
              />
            ),
          },
        }}
      />

      {ColorPickerPopover}
    </Box>
  )
}
export { FormBuilderColor }
