import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { isNil } from 'lodash'
import Colors from '#/styles/Colors'

const Wrapper = styled('div')(({ bold, level }) => ({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  fontWeight: bold ? 700 : 400,
  color: Colors.feedback[level]?.color,
}))

const TypeString = ({
  value,
  customStyle = {},
  prefix,
  suffix,
  level,
  classes = {},
  bold,
}) => {
  if (isNil(value)) return <span>-</span>

  if (typeof value === 'object') {
    console.warn('Error: Column string with object')
    return <span>-</span>
  }

  return (
    <Tooltip title={value} aria-label={value}>
      <Wrapper style={customStyle} level={level} bold={bold}>
        {prefix && <span>{prefix}</span>}
        <div className={classes?.overMe}>{value}</div>
        {suffix && <span>{suffix}</span>}
      </Wrapper>
    </Tooltip>
  )
}

export default TypeString
