import { InputAdornment } from '@mui/material'
import React from 'react'
import InputText from '#/components/FormControl/Inputs/InputText'

export function FormBuilderNumber(props) {
  return (
    <InputText
      {...props}
      type="number"
      onChange={data => {
        if (!data) {
          return props.onChange(null)
        }

        let value = Number(data)

        if (props.type === 'integer') {
          value = parseInt(data, 10)
        }

        props.onChange(value)
      }}
      InputProps={{
        endAdornment: props.suffix ? (
          <InputAdornment position="end">{props.suffix}</InputAdornment>
        ) : null,
      }}
    />
  )
}
