import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

export function FormBuilderMessage(props) {
  return props.value ? (
    <div>
      <Alert severity={props.variation ? props.variation : 'error'}>
        {props.label && <AlertTitle>{props.label}</AlertTitle>}
        {props.value}
      </Alert>
    </div>
  ) : null
}
