import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import CheckIconOutline from '@mui/icons-material/CheckCircleOutline'
import * as Sentry from '@sentry/react'
import { Box, Button, IconButton } from '@mui/material'
import QRCode from 'react-qr-code'
import { styled } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { FormBuilder } from '@chipinside/formbuilder'
import { getUserInfo } from '#/store/ducks/auth'
import Modal from '#/components/Modal'
import { usePermissions } from '#/store/hooks/permissions'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'
import CowmedGrid from '#/components/Grid'
import Translate from '#/components/Translate'

const SENTRY_URL = 'https://cowmed.sentry.io/issues/?query='

const Code = styled('span')({
  backgroundColor: 'rgb(187, 49, 63)',
  color: 'white',
  position: 'absolute',
  bottom: 5,
  right: 5,
  padding: '2px 5px',
  borderRadius: 5,
  fontSize: 12,
})

const StyledBox = styled(Box)(({ theme }) => ({
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'height': '100%',
  'overflow': 'hidden',
  '& > p': {
    overflowWrap: 'anywhere',
    margin: '2px',
  },
  '& > svg': {
    [theme.breakpoints.up('xs')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
    },
  },
}))

const Response = ({ closeModal }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <CheckIconOutline color="primary" style={{ fontSize: 150, margin: 10 }} />
    <Typography component="p" variant="h6" style={{ textAlign: 'center' }}>
      {Translate({
        messageKey: 'register_item_updated_success_male',
        params: { item: 'feedback' },
      })}
    </Typography>
    <Button
      variant="contained"
      sx={{ mt: 2, color: 'white' }}
      onClick={closeModal}
    >
      {Translate({ messageKey: 'close' })}
    </Button>
  </Box>
)

export default function UserFeedback({ error, componentStack, backend }) {
  const {
    permissions: {
      system: { accessTelescope },
    },
  } = usePermissions({
    system: ['access_telescope'],
  })

  const { eventId } = error

  const theme = useTheme()
  const mdQuery = useMediaQuery(theme.breakpoints.down('md'))

  const userInfo = useSelector(getUserInfo, isEqual)
  const [open, setOpen] = useState(false)
  const [openResponse, setOpenResponse] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    Sentry.captureFeedback({
      event_id: eventId,
      name: userInfo.name,
      email: userInfo.email,
      comments: 'Auto: The sentry screen has been displayed',
    })
    return () => {}
  }, [eventId])

  const closeModal = () => {
    setOpen(false)
    setOpenResponse(false)
    const { pathname } = window.location

    // Verifica se a string começa com "/farm"
    if (pathname.startsWith('/farm')) {
      // Divide o caminho e substitui pela nova rota, mantendo o slug da fazenda
      const newPath = `/farm/${pathname.split('/')[2]}`
      window.location.replace(newPath)
    } else {
      window.location.replace('/')
    }
  }

  const formData = {
    fields: [
      {
        cols: 12,
        name: 'sentry-message',
        type: 'message',
        variation: 'info',
        value: Translate({ messageKey: 'report_cause_of_error' }),
      },
      {
        cols: mdQuery ? 6 : 12,
        default: null,
        disabled: false,
        events: [],
        hidden: false,
        label: Translate({ messageKey: 'name' }),
        name: 'name',
        rules: {
          max: {
            value: 256,
            message: Translate({
              messageKey: 'input_max_caracters',
              params: {
                field: Translate({ messageKey: 'name' }),
                size: '256',
              },
            }),
          },
          required: {
            value: true,
            message: Translate({ messageKey: 'required_field' }),
          },
        },
        type: 'text',
        value: userInfo?.name,
      },
      {
        cols: mdQuery ? 6 : 12,
        default: null,
        disabled: false,
        events: [],
        hidden: false,
        label: Translate({ messageKey: 'email' }),
        name: 'email',
        rules: {
          max: {
            value: 256,
            message: Translate({
              messageKey: 'input_max_caracters',
              params: {
                field: Translate({ messageKey: 'name' }),
                size: '256',
              },
            }),
          },
          required: {
            value: true,
            message: Translate({ messageKey: 'required_field' }),
          },
        },
        type: 'text',
        value: userInfo?.email,
      },
      {
        cols: 12,
        default: null,
        disabled: false,
        events: [],
        hidden: false,
        label: Translate({ messageKey: 'comment_plural' }),
        name: 'comments',
        rules: {
          max: {
            value: 256,
            message: Translate({
              messageKey: 'input_max_caracters',
              params: {
                field: Translate({ messageKey: 'comment_plural' }),
                size: '256',
              },
            }),
          },
          required: {
            value: true,
            message: Translate({ messageKey: 'required_field' }),
          },
        },
        type: 'textarea',
        value: null,
      },
    ],
  }

  const ErrorCopy = () => {
    const stringError = JSON.stringify({
      url: window?.location?.href,
      id: eventId,
      sentry_url: `${SENTRY_URL}${eventId}`,
      ...(backend
        ? error
        : {
            error: String(error),
            trace: String(componentStack),
          }),
    })

    return navigator.clipboard.writeText(stringError)
  }

  const ErrorInfo = () => (
    <StyledBox>
      <p>{window?.location?.pathname}</p>
      {accessTelescope ? (
        <a href={`${SENTRY_URL}${eventId}`} target="__blank">
          {eventId}
        </a>
      ) : (
        <p>{eventId}</p>
      )}

      <QRCode size={200} value={`${SENTRY_URL}${eventId}`} />
      {accessTelescope ? (
        <IconButton aria-label="copy" onClick={ErrorCopy}>
          <ContentCopyIcon />
        </IconButton>
      ) : null}
      {error?.status_code && <Code>{error?.status_code}</Code>}
    </StyledBox>
  )

  return (
    <React.Fragment>
      <Modal
        maxWidth="lg"
        open={open}
        title={Translate({ messageKey: 'processing_error_contact_support' })}
        element={
          <Box sx={{ flexGrow: 1 }}>
            <CowmedGrid container spacing={2}>
              <CowmedGrid size={{ xs: 12, md: 8 }}>
                <FormBuilder
                  formData={formData}
                  onSubmit={params => {
                    const userFeedback = { event_id: eventId, ...params }
                    if (error?.status_code === 500) {
                      fetcher({
                        method: 'post',
                        controller: endpoint.sentry.backend_feedback(),
                        params: userFeedback,
                      }).catch(error => console.error(error))
                    } else {
                      Sentry.captureFeedback(userFeedback)
                    }
                    setOpenResponse(true)
                  }}
                  extraButtons={[
                    {
                      title: Translate({ messageKey: 'close' }),
                      props: {
                        key: 'button-userfeedback',
                        onClick: closeModal,
                      },
                    },
                  ]}
                />
              </CowmedGrid>
              <CowmedGrid size={{ xs: 12, md: 4 }}>
                <ErrorInfo />
              </CowmedGrid>
            </CowmedGrid>
          </Box>
        }
      />
      <Modal
        maxWidth="sm"
        open={openResponse}
        onClose={closeModal}
        element={<Response closeModal={closeModal} />}
      />
    </React.Fragment>
  )
}
