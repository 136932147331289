/* eslint-disable import/no-cycle */
import { isEmpty } from 'lodash'
import { FindMyCowElements } from './Elements'

export const FindMyCowHeader = ({ id, data }) => {
  if (isEmpty(data)) return null

  return (
    <div key={id}>
      {data?.map(item => (
        <div key={item.id} id={item.id}>
          <FindMyCowElements {...item} />
        </div>
      ))}
    </div>
  )
}
