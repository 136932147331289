import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import makeStyles from '@mui/styles/makeStyles'
import { FormHelperText } from '@mui/material'
import MarkdownView from '#/components/MarkdownView'
import Colors from '#/styles/Old/Colors'

// remove react-showdown from package.json
// when we change the markdown editor

const useStyles = makeStyles(theme => ({
  formLabel: {
    fontSize: 12,
    position: 'relative',
  },
  containerRow: {
    minHeight: '24vh',
    maxHeight: '48vh',
    display: 'flex',
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'column',
    },
  },
  containerColumn: {
    flex: 1,
  },
  textArea: {
    width: '100%',
    height: '100%',
    minHeight: '24vh',
    margin: 0,
    border: `1px solid ${Colors.borderGray}`,
    padding: 8,
    resize: 'none',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'monospace',
  },
  markdownView: {
    minHeight: '100%',
    maxHeight: '100%',
    border: `1px solid ${Colors.borderGray}`,
    padding: 8,
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      borderLeft: 0,
    },
    [theme.breakpoints.down('xl')]: {
      height: '24vh',
      borderTop: 0,
    },
  },
}))

const TextEditor = ({
  label,
  value,
  defaultValue,
  onChange,
  error,
  ...rest
}) => {
  const classes = useStyles()
  const initial = value || defaultValue
  const [selectedVal, setSelectedVal] = React.useState(
    initial ? String(initial) : '',
  )

  const handleChange = value => {
    setSelectedVal(value)
    if (onChange) {
      onChange(value)
    }
  }

  React.useEffect(() => {
    setSelectedVal(value ?? '')
  }, [value])

  return (
    <div>
      <FormLabel
        className={classes.formLabel}
        required={rest.required}
        error={Boolean(error)}
      >
        {label}
      </FormLabel>
      <div className={classes.containerRow}>
        <div className={classes.containerColumn}>
          <textarea
            {...rest}
            className={classes.textArea}
            value={selectedVal}
            placeholder="..."
            onChange={event => handleChange(event.target.value)}
          />
        </div>
        <div className={classes.containerColumn}>
          <MarkdownView
            className={classes.markdownView}
            markdown={selectedVal}
          />
        </div>
      </div>
      {error && <FormHelperText error={Boolean(error)}>{error}</FormHelperText>}
    </div>
  )
}

export default TextEditor
