import React from 'react'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Language from '@mui/icons-material/Language'
import PhoneInput from 'react-phone-number-input/min'
import { FormHelperText } from '@mui/material'
import Translate from '#/components/Translate'

const MaterialInput = React.forwardRef((props, ref) => (
  <TextField {...props} inputRef={ref} />
))

const CustomSelect = React.forwardRef(({ value, options, onChange }, ref) => {
  return (
    <FormControl
      variant="standard"
      ref={ref}
      style={{ marginRight: 5, width: 56 }}
    >
      <InputLabel id="country-code-select-label" shrink>
        {Translate({ messageKey: 'country' })}
      </InputLabel>
      <Select
        id="country-code-select"
        value={value}
        onChange={e => onChange(e.target.value)}
        labelId="country-code-select-label"
        autoWidth
        displayEmpty
        renderValue={val =>
          !val ? (
            <Language
              viewBox="2 3 18 18"
              shapeRendering="geometricPrecision"
              style={{ width: 24, height: 16 }}
            />
          ) : (
            <img
              alt={val}
              src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${val}.svg`}
              width={24}
              height={16}
            />
          )
        }
      >
        {options?.map(({ value, label }) => (
          <MenuItem value={value} key={`${label}-${Math.random()}`}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

const InputPhone = ({
  value,
  label,
  defaultValue,
  defaultCountry,
  onChange,
  error,
  ...rest
}) => {
  const handleChange = (value = '') => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <>
      <PhoneInput
        {...rest}
        value={value}
        label={label}
        error={Boolean(error)}
        defaultCountry={defaultCountry || 'BR'}
        style={{ display: 'flex' }}
        type="tel"
        autoComplete="tel"
        onChange={handleChange}
        inputComponent={MaterialInput}
        countrySelectComponent={CustomSelect}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </>
  )
}

export default InputPhone
