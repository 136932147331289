import React from 'react'
import { Chip, Tooltip } from '@mui/material'
import { isNil } from 'lodash'

const CowmedChip = props => {
  const {
    value,
    customStyle = {},
    width = 103,
    variant,
    tooltip,
    ...rest
  } = props
  if (isNil(value)) return <span>-</span>

  const Component = (
    <Chip
      variant={variant}
      style={{ width, lineHeight: 1, ...customStyle }}
      label={value}
      {...rest}
    />
  )

  if (tooltip) {
    return (
      <Tooltip title={tooltip} aria-label={tooltip}>
        <div>{Component}</div>
      </Tooltip>
    )
  }

  return Component
}

export default CowmedChip
