import { Tooltip } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { styled } from '@mui/material/styles'
import { contrastColor } from '#/utils/contrastColor'
import CowmedChip from '#/components/Chip'

const Wrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 5,
  margin: '5px 0',
})

function TypePortfolio({ portfolios }) {
  if (isEmpty(portfolios)) return null

  return (
    <Wrapper>
      {portfolios?.map(portfolio => (
        <div
          key={portfolio.name}
          style={{ flex: '0 0 40%', boxSizing: 'border-box' }}
        >
          <Tooltip title={portfolio.name}>
            <div>
              <CowmedChip
                value={portfolio.name}
                size="small"
                customStyle={{
                  backgroundColor: portfolio.color,
                  color: contrastColor(portfolio.color),
                }}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </Wrapper>
  )
}

export default TypePortfolio
