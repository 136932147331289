import Palette from '#/styles/Palette'

export default {
  /* Basic */
  primary: Palette.green.self[500],

  lighterGray: Palette.gray.self[200],
  darkerGray: Palette.gray.self[700],

  danger: Palette.red.self[400],
  warning: Palette.orange.self[500],
  success: Palette.green.self[500],
  error: Palette.red.self[500],
  info: Palette.blue.light[400],
  muted: Palette.gray.self[600],
  theme: {
    colors: {
      primary: {
        light: Palette.green.nutrition[200],
        main: Palette.green.self[500],
        dark: Palette.green.nutrition[500],
      },
      secondary: {
        main: Palette.gray.self[600],
        light: Palette.gray.light[400],
        dark: Palette.gray.dark[100],
      },
      info: {
        main: Palette.blue.dark[500],
        light: Palette.blue.self[400],
        dark: Palette.blue.self[600],
      },
      text: {
        primary: Palette.gray.dark[900],
        main: Palette.gray.dark[900],
        secondary: Palette.gray.self[500],
      },
      error: {
        main: Palette.red.self[400],
      },
      white: {
        main: Palette.white.self[100],
        light: Palette.white.self[100],
        dark: Palette.gray.self[400],
        contrastText: Palette.gray.dark[100],
      },
      neutral: {
        main: Palette.white.self[100],
        light: Palette.white.self[100],
        dark: Palette.gray.self[400],
        contrastText: Palette.gray.dark[100],
      },
      baby: {
        main: Palette.pink.self[700],
        light: Palette.pink.light[700],
        dark: Palette.pink.dark[700],
        contrastText: Palette.white.self[100],
      },
    },
  },
  feedback: {
    success: {
      backgroundColor: Palette.green.mint[100],
      color: Palette.green.mint[700],
    },
    warning: {
      backgroundColor: Palette.orange.self[100],
      color: Palette.orange.self[600],
    },
    info: {
      backgroundColor: Palette.blue.self[100],
      color: Palette.blue.self[700],
    },
    danger: {
      backgroundColor: Palette.red.light[100],
      color: Palette.red.light[700],
    },
    neutral: {
      backgroundColor: 'inherit',
      color: Palette.gray.self[600],
    },
    error: {
      backgroundColor: Palette.red.light[100],
      color: Palette.red.light[700],
    },
  },

  tags: {
    new: {
      backgroundColor: Palette.green.self[100],
      color: Palette.green.self[700],
    },
  },

  devices: {
    ctech: {
      normal: {
        backgroundColor: Palette.blue.dark[500],
        color: Palette.white.self[100],
      },
      revision: {
        backgroundColor: Palette.orange.self[500],
        color: Palette.white.self[100],
      },
      replace: {
        backgroundColor: Palette.orange.dark[600],
        color: Palette.white.self[100],
      },
    },
  },

  nps: {
    // Status
    finalized: {
      backgroundColor: Palette.green.mint[100],
      color: Palette.green.mint[700],
    },
    awaiting: {
      backgroundColor: Palette.orange.self[100],
      color: Palette.orange.self[600],
    },
    suspended: {
      backgroundColor: Palette.red.light[100],
      color: Palette.red.light[700],
    },
    ongoing: {
      backgroundColor: Palette.blue.self[100],
      color: Palette.blue.self[700],
    },
    // Answer group
    detractor: {
      backgroundColor: Palette.red.light[100],
      color: Palette.red.light[700],
    },
    neutral: {
      backgroundColor: Palette.orange.self[100],
      color: Palette.orange.self[600],
    },
    promotter: {
      backgroundColor: Palette.green.mint[100],
      color: Palette.green.mint[700],
    },
  },

  sex: {
    male: {
      backgroundColor: Palette.purple.dark[500],
      text: Palette.white.self[100],
    },
    female: {
      backgroundColor: Palette.purple.self[600],
      text: Palette.white.self[100],
    },
  },

  reproduction: {
    lateInseminated: {
      background: Palette.orange.light[100],
      text: Palette.orange.light[600],
    },
    inseminated: {
      background: Palette.green.self[200],
      text: Palette.green.self[700],
    },
    calf: {
      background: Palette.pink.self[200],
      text: Palette.pink.self[700],
    },
    calfNewBorn: {
      background: Palette.pink.light[200],
      text: Palette.pink.self[600],
    },
    empty: {
      background: Palette.green.night[200],
      text: Palette.green.night[700],
    },
    emptyPostDelivery: {
      background: Palette.yellow.self[200],
      text: Palette.yellow.self[700],
    },
    heifer: {
      background: Palette.purple.light[100],
      text: Palette.purple.light[600],
    },
    male: {
      background: Palette.blue.zinc[200],
      text: Palette.blue.zinc[700],
    },
    prostaglandin: {
      background: Palette.orange.dark[200],
      text: Palette.orange.light[700],
    },
    pregnant: {
      background: Palette.purple.self[200],
      text: Palette.purple.self[700],
    },
    pregnantPreDelivery: {
      background: Palette.purple.dark[100],
      text: Palette.purple.dark[700],
    },
    hormoneImplanted: {
      background: Palette.red.peach[200],
      text: Palette.red.light[700],
    },
    implantRemoved: {
      background: Palette.red.salmon[200],
      text: Palette.red.self[700],
    },
    birth: {
      backgroundColor: Palette.pink.birth[50],
      color: Palette.pink.birth[700],
    },
    heat: {
      backgroundColor: Palette.purple.reproduction[100],
      color: Palette.purple.reproduction[700],
    },
  },

  production: {
    lactating: {
      background: Palette.blue.dark[100],
      text: Palette.blue.dark[500],
    },
    dry: {
      background: Palette.gray.self[200],
      text: Palette.gray.self[600],
    },
  },

  health: {
    normal: {
      backgroundColor: Palette.green.self[100],
      color: Palette.green.self[700],
      graph: Palette.green.self[500],
    },
    challenge: {
      backgroundColor: Palette.yellow.self[100],
      color: Palette.yellow.self[700],
      graph: Palette.orange.self[500],
    },
    observation: {
      backgroundColor: Palette.orange.light[100],
      color: Palette.orange.light[600],
      graph: Palette.orange.light[500],
    },
    critical: {
      backgroundColor: Palette.red.self[100],
      color: Palette.red.self[700],
      graph: Palette.red.self[400],
    },
  },

  condition: {
    deteriorate: {
      background: Palette.red.self[100],
      text: Palette.red.self[700],
    },
    stable: {
      background: Palette.yellow.self[100],
      text: Palette.yellow.self[700],
    },
    improve: {
      background: Palette.green.self[100],
      text: Palette.green.self[700],
    },
    unknown: {
      background: Palette.gray.self[200],
      text: Palette.gray.self[600],
    },
  },

  heat: {
    verified: {
      background: Palette.green.mint[100],
      text: Palette.green.mint[700],
    },
    awaiting: {
      background: Palette.orange.self[100],
      text: Palette.orange.self[600],
    },
  },

  animal: {
    breathing: {
      backgroundColor: Palette.orange.self[500],
      color: Palette.white.self[100],
    },
    discard: {
      backgroundColor: Palette.gray.self[300],
      color: Palette.gray.self[600],
    },
    primiparous: {
      backgroundColor: Palette.blue.light[500],
      color: Palette.blue.light[700],
    },
    multiparous: {
      backgroundColor: Palette.blue.marine[500],
      color: Palette.blue.marine[700],
    },
    adult: {
      backgroundColor: Palette.blue.dark[300],
      color: Palette.blue.dark[500],
    },
  },

  batch: {
    backgroundColor: Palette.blue.dark[500],
    color: Palette.white.self[100],
  },

  contract: {
    trial: {
      backgroundColor: Palette.green.light[600],
      color: Palette.white.self[100],
    },
    active: {
      backgroundColor: Palette.green.self[700],
      color: Palette.white.self[100],
    },
    waiting: {
      backgroundColor: Palette.orange.self[500],
      color: Palette.white.self[100],
    },
    suspended: {
      backgroundColor: Palette.orange.light[600],
      color: Palette.white.self[100],
    },
    inactive: {
      backgroundColor: Palette.red.light[500],
      color: Palette.white.self[100],
    },
    expired: {
      backgroundColor: Palette.gray.self[400],
      color: Palette.white.self[100],
    },
  },

  plan: {
    monitoring: {
      backgroundColor: Palette.blue.light[400],
      color: Palette.white.self[100],
    },
    cowconnect: {
      backgroundColor: Palette.blue.self[200],
      color: Palette.white.self[100],
    },
  },

  vic: {
    action: {
      message: Palette.green.light[200],
    },
    information: {
      message: Palette.blue.self[200],
    },
    baby: {
      message: Palette.pink.light[200],
    },
  },

  dtr: {
    normal: Palette.green.self[500],
    moderated: Palette.orange.self[500],
    intense: Palette.red.self[400],
  },

  ppi: {
    normal: Palette.green.self[500],
    challenge: Palette.red.self[400],
  },

  thi: {
    // 50 to 67
    thermal_comfort: {
      backgroundColor: Palette.blue.sky[100],
      color: Palette.blue.sky[700],
      chip: Palette.blue.sky[400],
    },
    // 68 to 71
    stress_zone: {
      backgroundColor: Palette.orange.sand[100],
      color: Palette.orange.sand[700],
      chip: Palette.orange.sand[400],
    },
    // 72 to 79
    mild_to_moderate_stress: {
      backgroundColor: Palette.orange.brick[100],
      color: Palette.orange.brick[700],
      chip: Palette.orange.brick[400],
    },
    // 80 to 89
    moderate_to_severe_stress: {
      backgroundColor: Palette.red.salmon[100],
      color: Palette.red.salmon[700],
      chip: Palette.red.salmon[400],
    },
    // 90 to 100
    severe_stress: {
      backgroundColor: Palette.red.peach[100],
      color: Palette.red.peach[700],
      chip: Palette.red.peach[400],
    },
  },
  variation: {
    deteriorate: {
      color: Palette.red.self[700],
    },
    improve: {
      color: Palette.green.self[700],
    },
  },
  protocol: {
    scheduled: {
      color: Palette.orange.light[600],
      backgroundColor: Palette.orange.light[100],
    },
    sync: {
      color: Palette.blue.dark[600],
      backgroundColor: Palette.blue.dark[200],
    },
    pre_sync: {
      color: Palette.blue.light[600],
      backgroundColor: Palette.blue.light[100],
    },
    heat_observation: {
      color: Palette.purple.dark[400],
      backgroundColor: Palette.purple.dark[100],
    },
    completed: {
      color: Palette.green.mint[700],
      backgroundColor: Palette.green.mint[100],
    },
    canceled: {
      color: Palette.red.light[700],
      backgroundColor: Palette.red.light[100],
    },
    service: {
      color: Palette.purple.reproduction[600],
      backgroundColor: Palette.purple.reproduction[100],
    },
  },
  inapt: {
    discard: Palette.red.self[400],
    induction: '#739abf',
    unfit: '#ffc107',
  },
  services: Palette.purple.dark[300],
}
