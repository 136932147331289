import React from 'react'
import TextField from '@mui/material/TextField'

const InputText = ({ value, defaultValue, onChange, error, ...rest }) => {
  const initial = value || defaultValue
  const [selectedVal, setSelectedVal] = React.useState(
    initial ? String(initial) : '',
  )

  const handleChange = value => {
    setSelectedVal(value)
    if (onChange) {
      onChange(value)
    }
  }

  React.useEffect(() => {
    setSelectedVal(value)
  }, [value])

  return (
    <TextField
      fullWidth
      {...rest}
      error={Boolean(error)}
      helperText={error}
      value={selectedVal ?? ''}
      onChange={event => handleChange(event.target.value)}
      slotProps={{
        input: rest.InputProps,
        inputLabel: { shrink: true },
      }}
    />
  )
}

export default InputText
