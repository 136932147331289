import React, { useEffect } from 'react'
import isNil from 'lodash/isNil'
import FormLabel from '@mui/material/FormLabel'
import Switch from '@mui/material/Switch'
import { FormControlLabel } from '@mui/material'
import { useAsyncState } from '#/store/hooks/asyncState'

const InputSwitch = props => {
  const {
    label,
    defaultValue,
    value,
    onChange,
    disabled,
    required,
    labelPlacement = 'top',
    error,
    helperText,
  } = props

  const [selectedVal, setSelectedVal] = useAsyncState(
    typeof value === 'boolean' ? value : defaultValue,
  )

  useEffect(() => {
    if (!isNil(defaultValue) && onChange) {
      onChange(defaultValue)
    }
  }, [])

  const handleChange = () => {
    setSelectedVal(!selectedVal).then(selected => {
      if (onChange) {
        onChange(selected)
      }
    })
  }

  //Fix switch and label center position
  const styledLabel =
    labelPlacement === 'top' || labelPlacement === 'bottom'
      ? { alignItems: 'start' }
      : {}

  const InputSwitch = () => (
    <Switch
      color="primary"
      checked={selectedVal}
      value={selectedVal}
      inputProps={{ 'aria-label': label }}
      onChange={handleChange}
      disabled={disabled}
      required={required}
    />
  )
  //Manipulate error color and required asterisk
  const InputLabel = () => (
    <FormLabel
      style={{ fontSize: 12, position: 'relative' }}
      required={required}
      error={Boolean(error)}
    >
      {label}
    </FormLabel>
  )

  return (
    <div>
      <FormControlLabel
        value="start"
        label={<InputLabel />}
        control={<InputSwitch />}
        labelPlacement={labelPlacement}
        style={{ width: '100%', ...styledLabel }}
      />
      <FormLabel style={{ fontSize: 12 }}>{helperText}</FormLabel>
    </div>
  )
}

export default InputSwitch
