import React, { useRef } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import FolderIcon from '@mui/icons-material/Folder'
import TextField from '@mui/material/TextField'
import Palette from '#/styles/Palette'

const styles = {
  inputFileContainer: {
    width: '100%',
    position: 'relative',
  },
  inputFileNode: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
    cursor: 'pointer',
    display: 'block',
    opacity: 0,
  },
}

const InputFile = ({
  value,
  defaultValue,
  onChange,
  disabled,
  accept = '',
  style = {},
  ...rest
}) => {
  const fileInput = useRef(null)

  const initial = value || defaultValue
  const [selectedFile, setSelectedFile] = React.useState({
    name: initial ? String(initial) : '',
  })

  const handleChange = async () => {
    if (fileInput?.current?.files?.[0]) {
      const file = fileInput.current.files[0]

      setSelectedFile(file)
      onChange?.(file)
    }
  }

  return (
    <div style={{ ...styles.inputFileContainer, ...style }}>
      <TextField
        {...rest}
        type="text"
        value={selectedFile.name}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <FolderIcon style={{ color: Palette.gray.dark[500] }} />
              </InputAdornment>
            ),
          },
        }}
        style={{ pointerEvents: 'none' }}
        fullWidth
        disabled={!!disabled}
      />
      <input
        ref={fileInput}
        type="file"
        style={styles.inputFileNode}
        accept={accept}
        onChange={handleChange}
        disabled={!!disabled}
      />
    </div>
  )
}

export default InputFile
