import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

const InputRadio = ({
  label,
  options,
  value,
  defaultValue,
  onChange,
  orientation = 'row',
  ...rest
}) => {
  const [selectedVal, setSelectedVal] = React.useState(value)
  const handleChange = value => {
    setSelectedVal(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <div>
      <FormLabel
        style={{ fontSize: 12, position: 'relative' }}
        required={rest.required}
        error={Boolean(rest.error)}
      >
        {label}
      </FormLabel>
      <RadioGroup
        defaultValue={defaultValue}
        value={selectedVal}
        onChange={event => handleChange(event.target.value)}
        row={orientation === 'row'}
        {...(orientation === 'column'
          ? { style: { gap: 10, marginTop: 15 } }
          : { style: { minHeight: 39 } })}
      >
        {options.map((opt, key) => {
          return (
            <FormControlLabel
              color="text"
              key={key}
              value={opt.value.toString()}
              control={<Radio color="primary" />}
              label={
                <div>
                  <span style={{ color: '#212121' }}>{opt.label}</span>
                  {orientation === 'column' ? (
                    <span style={{ display: 'block', color: 'gray' }}>
                      {opt.description}
                    </span>
                  ) : null}
                </div>
              }
              disabled={opt.disabled || rest.disabled}
              labelPlacement="end"
              style={{
                marginBottom: 0,
                ...(orientation === 'column'
                  ? { display: 'flex', alignItems: 'flex-start' }
                  : {}),
              }}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

export default InputRadio
