import { Button } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'
import { isEmpty } from 'lodash'
import { CreateFields } from '@chipinside/formbuilder'
import CowmedGrid from '#/components/Grid'

export function FormBuilderCollapse(props) {
  const { name, label, fields } = props

  const hasValue = fields?.some(f => !isEmpty(f.value))

  const [expanded, setExpanded] = React.useState(hasValue)

  return (
    <>
      <Button
        onClick={() => setExpanded(prevState => !prevState)}
        aria-expanded={name}
        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{ width: '100%' }}
      >
        {label}
      </Button>
      <Collapse in={expanded} timeout="auto">
        <CowmedGrid container spacing={2} mt={2}>
          <CreateFields
            fields={fields}
            form={props.form}
            setValues={props.setValues}
            formevents={props.formevents}
          />
        </CowmedGrid>
      </Collapse>
    </>
  )
}
