import React from 'react'
import { withStyles } from '@mui/styles'
import switchFn from '#/utils/switchfn'
import LineClampStyle from '#/styles/LineClamp'
import TypeLink from './Types/link'
import TypeDate from './Types/date'
import TypeTimestamp from './Types/timestamp'
import TypeBoolean from './Types/boolean'
import TypeHasDataBadge from './Types/hasDataBadge'
import TypeWarningBadge from './Types/warningBadge'
import TypeReproduction from './Types/reproduction'
import TypeHealth from './Types/healthStatus'
import TypeCollarStatusBadge from './Types/collarStatusBadge'
import TypeCollarStockBadge from './Types/collarStockBadge'
import TypeString from './Types/string'
import TypeStringArray from './Types/stringArray'
import TypeItemList from './Types/itemsList'
import TypeEventGroupList from './Types/eventGroupList'
import TypeSequencer from './Types/sequencer'
import TypeAnimalStatus from './Types/animalStatus'
import TypeUnitStatus from './Types/unitStatus'
import TypeUnitCollarStatus from './Types/unitCollarStatus'
import TypeJobStatusBadge from './Types/jobStatusBadge'
import TypeProductionBadge from './Types/productionBadge'
import TypeMonitoring from './Types/monitoring'
import TypeEventInfos from './Types/eventInfos'
import TypeQuickInfo from './Types/quickInfo'
import TypeCTech from './Types/ctech'
import TypeCEnv from './Types/cenv'
import TypeCalvesBadge from './Types/calvesBadge'
import TypeFarmContractBadge from './Types/farmContractBadge'
import TypeCcomBadgeLink from './Types/ccomBadgeLink'
import TypePlansBadge from './Types/plansBadge'
import TypeMonthlyVariation from './Types/monthlyVariation'
import TypeDTR from './Types/dtr'
import TypeAiTimeline from './Types/AiTimeline'
import TypeHeatStrength from './Types/heatStrength'
import TypeAvatarLink from './Types/AvatarLink'
import TypeFromNow from './Types/fromNow'
import TypeStatistic from './Types/Statistics'
import TypeButtonLink from './Types/ButtonLink'
import TypeInapt from './Types/inapt'
import TypePPI from './Types/ppi'
import TypeMultiData from './Types/MultiData'
import TypeProduction from './Types/production'
import TypeBreathingIndicator from './Types/breathingIndicator'
import TypeChip from './Types/chip'
import TypeInsights from './Types/insights'
import TypeHeatStatus from './Types/heatStatus'
import TypeEnum from './Types/enum'
import TypeMinutesToHours from '#/components/FormatCell/Types/minutesToHours'
import TypeFailsReportVariation from './Types/failsReportVariation'
import TypeCComCoverage from '#/components/FormatCell/Types/ccomCoverage'
import TypeNPSCampaignAnswerGroup from '#/components/FormatCell/Types/npsCampaignAnswerGroup'
import TypeNPSCampaignStatus from '#/components/FormatCell/Types/npsCampaignStatus'
import TypeCComStatus from '#/components/FormatCell/Types/ccomStatus'
import TypeThi from '#/components/FormatCell/Types/thi'
import TypeBatchesList from '#/components/FormatCell/Types/batchList'
import TypeVariation from '#/components/FormatCell/Types/variation'
import TypePregnancyDiagnostics from './Types/pregnancyDiagnostics'
import TypePortfolio from '#/components/FormatCell/Types/portfolio'
import TypeProtocolStatus from '#/components/FormatCell/Types/protocolStatus'

const styles = () => ({
  overMe: {
    ...LineClampStyle,
    maxWidth: 250,
    maxHeight: 50,
  },
})

function FormatCell(props) {
  const { type } = props
  /* prettier-ignore */
  const Types = {
    'ccom_status':                  <TypeCComStatus {...props} />,
    'ccom_coverage':                <TypeCComCoverage {...props} />,
    'health_status':                <TypeHealth {...props} />,
    'campaign_status':              <TypeNPSCampaignStatus {...props}/>,
    'campaign_answer_group':        <TypeNPSCampaignAnswerGroup {...props}/>,
    'thi':                          <TypeThi {...props}/>,
    'batches_list':                 <TypeBatchesList {...props}/>,
    'breathing_indicator':          <TypeBreathingIndicator {...props} />,
    'variation':                    <TypeVariation {...props} />,
    'pregnancy_diagnostics':        <TypePregnancyDiagnostics {...props} />,
    'inapt':                        <TypeInapt {...props} />,
    'reproduction_status':          <TypeReproduction {...props} />,
    'production_status':            <TypeProduction {...props} />,
    'portfolio':                    <TypePortfolio {...props} />,
    'protocol_status':              <TypeProtocolStatus {...props} />,

    // TODO
    'chip':                         <TypeChip {...props} />,
    'ppi-status':                   <TypePPI {...props} />,
    'ai-timeline':                  <TypeAiTimeline {...props} />,
    'avatar-link':                  <TypeAvatarLink {...props} />,
    'animal-status':                <TypeAnimalStatus {...props} />,
    'boolean':                      <TypeBoolean {...props} />,
    'button-link':                  <TypeButtonLink {...props} />,
    'calves-badge':                 <TypeCalvesBadge {...props} />,
    'collar-status-badge':          <TypeCollarStatusBadge {...props} />,
    'collar-stock-badge':           <TypeCollarStockBadge {...props} />,
    'monthly-variation':            <TypeMonthlyVariation {...props} />,
    'ccom-badge-link':              <TypeCcomBadgeLink {...props} />,
    'c-tech':                       <TypeCTech {...props} />,
    'c-env':                        <TypeCEnv {...props} />,
    'date':                         <TypeDate {...props} />,
    'dtr':                          <TypeDTR {...props} />,
    'event-group-list':             <TypeEventGroupList {...props} />,
    'event-infos':                  <TypeEventInfos {...props} />,
    'farm-contract-badge':          <TypeFarmContractBadge {...props} />,
    'from-now':                     <TypeFromNow {...props} />,
    'has-data-badge':               <TypeHasDataBadge {...props} />,
    'heat-strength':                <TypeHeatStrength {...props} />,
    'items-list':                   <TypeItemList {...props} />,
    'job-status-badge':             <TypeJobStatusBadge {...props} />,
    'link':                         <TypeLink {...props} />,
    'production-badge':             <TypeProductionBadge {...props} />,
    'monitoring':                   <TypeMonitoring {...props} />,
    'plans-badge':                  <TypePlansBadge {...props} />,
    'quick-info':                   <TypeQuickInfo {...props} />,
    'sequencer':                    <TypeSequencer {...props} />,
    'statistic':                    <TypeStatistic {...props} />,
    'string':                       <TypeString {...props} />,
    'string-array':                 <TypeStringArray {...props} />,
    'timestamp':                    <TypeTimestamp {...props} />,
    'unit-collar-status':           <TypeUnitCollarStatus {...props} />,
    'unit-status':                  <TypeUnitStatus {...props} />,
    'warning-badge':                <TypeWarningBadge {...props} />,
    'multi-data':                   <TypeMultiData {...props} />,
    'insights':                     <TypeInsights {...props} />,
    'heat-status':                  <TypeHeatStatus {...props} />,
    'enum':                         <TypeEnum {...props} />,
    'minutes-to-hours':             <TypeMinutesToHours {...props} />,
    'fails-report-variation':       <TypeFailsReportVariation {...props} />,
    'default': null
  }

  const switchA = switchFn(Types, 'default')

  return switchA(type)
}

export default withStyles(styles)(FormatCell)
