import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import CowmedGrid from '#/components/Grid'
import LinearIndeterminate from '#/components/Loader'
import { LabelWithHelp } from '../Fields/LabelWithHelp'
import Translate from '#/components/Translate'
import Moment from '#/components/Moment'

const ButtonsContainer = styled(CowmedGrid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 16,
})

/* eslint-disable no-unused-vars */
/* prettier-ignore */
const FormBuilderComponents = {
  Loader:                       props => <LinearIndeterminate  {...props} absolute />,
  Container:                    props => <CowmedGrid           {...props} container spacing={2} />,
  ItemWrapper:                  props => <CowmedGrid           {...props}/>,
  ButtonsContainer:             props => <ButtonsContainer     {...props} size={12} />,
  SubmitButton:                 props => <Button               {...props} variant="contained"/>,
  ExtraButtons:                 props => <Button               {...props} variant="outlined" color="primary" />,
  Form:                         props => <form                 {...props} noValidate />,
  LabelField:                   props => <LabelWithHelp        {...props}  />,
  Moment:                       props => Moment(props),
  Translate:                    props => Translate({ messageKey: props?.key, params:{item: props?.params?.label} }),
}

export { FormBuilderComponents }
