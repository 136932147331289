import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { useFieldArray } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import { CreateFields, getGroupDefaultValues } from '@chipinside/formbuilder'
import CowmedGrid from '#/components/Grid'
import Translate from '#/components/Translate'

const StyledButton = styled(Button)({
  'backgroundColor': 'color-mix(in srgb, currentColor 5%, transparent)',
  'padding': '0 15px',
  '&:hover': {
    backgroundColor: 'color-mix(in srgb, currentColor 15%, transparent)',
  },
})

function Footer(props) {
  const {
    count,
    min_entries,
    max_entries,
    line_button_add_text,
    line_button_remove_text,
    onAdd,
    onRemove,
  } = props

  return (
    <Box mt={2} gap={1} display="flex">
      <StyledButton
        size="small"
        onClick={onAdd}
        disabled={count === max_entries}
      >
        {line_button_add_text ||
          Translate({
            messageKey: 'add_item',
            params: { item: Translate({ messageKey: 'row' }) },
          })}
      </StyledButton>
      <StyledButton
        size="small"
        color="error"
        onClick={() => onRemove(count - 1)}
        disabled={count === min_entries}
      >
        {line_button_remove_text || Translate({ messageKey: 'remove_row' })}
      </StyledButton>
    </Box>
  )
}

export function FormBuilderGroup(props) {
  const { fields, name, label, form, line_separator } = props

  const {
    fields: arrayFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name,
  })

  const handleAdd = () => append(getGroupDefaultValues(fields, 1) ?? {})
  const handleRemove = index => remove(index)

  return (
    <Box>
      {label && (
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
      )}

      <Stack rowGap={2} divider={line_separator ? <Divider flexItem /> : null}>
        {arrayFields.map((item, i) => (
          <CowmedGrid
            container
            spacing={2}
            key={item.id}
            sx={{
              'borderLeft': 3,
              'pl': 3,
              'py': 2,
              'borderColor': '#c1c1c1',
              '&:empty': {
                display: 'none',
              },
            }}
          >
            <CreateFields
              fields={fields.map(f => ({
                ...f,
                name: `${name}[${i}].${f.name}`,
                label: f.label?.replace('{row}', i + 1),
              }))}
              setValues={props.setValues}
              form={props.form}
              formEvents={props.formevents}
            />
          </CowmedGrid>
        ))}
      </Stack>

      <Footer
        {...props}
        onAdd={handleAdd}
        onRemove={handleRemove}
        count={arrayFields.length}
      />
    </Box>
  )
}
