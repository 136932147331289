import { Typography } from '@mui/material'
import React from 'react'

export function FormBuilderSession(props) {
  return (
    <>
      <Typography
        key={props.name}
        variant="h6"
        style={{ fontWeight: 600, margin: 0 }}
        gutterBottom
      >
        {props.label}
      </Typography>
      {props?.description && <span>{props?.description}</span>}
    </>
  )
}
