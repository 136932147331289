import Translate from '#/components/Translate'

/**
 * Valida a latitude para garantir que seja um número válido no intervalo de -90 a 90 graus decimais.
 * @param {number} latitude - A latitude a ser validada.
 * @returns {null|string} - Retorna null se a latitude for válida, caso contrário, retorna uma mensagem de erro.
 */
export const isValidLatitude = latitude => {
  // Verifica se a latitude é um número e está no intervalo válido
  if (latitude && !isNaN(latitude) && latitude >= -90 && latitude <= 90) {
    return null // Retorna nulo para indicar que a latitude é válida
  }

  return Translate({ messageKey: 'invalid_latitude' })
}

/**
 * Valida a longitude para garantir que seja um número válido no intervalo de -180 a 180 graus decimais.
 * @param {number} longitude - A longitude a ser validada.
 * @returns {null|string} - Retorna null se a longitude for válida, caso contrário, retorna uma mensagem de erro.
 */
export const isValidLongitude = longitude => {
  // Verifica se a longitude é um número e está no intervalo válido
  if (longitude && !isNaN(longitude) && longitude >= -180 && longitude <= 180) {
    return null // Retorna nulo para indicar que a longitude é válida
  }

  return Translate({ messageKey: 'invalid_longitude' })
}
