/* eslint-disable no-bitwise */
/**
 * Function to determine the contrast color (black or white) based on the background color.
 * @param {string} color - Hexadecimal color code.
 * @returns {string} - Contrast color ('black' or 'white').
 */
export const contrastColor = color => {
  if (!color) return null
  // Function to convert hexadecimal color code to RGB.
  const hexToRgb = hex => {
    const bigint = parseInt(hex.slice(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return { r, g, b }
  }

  // Function to calculate luminosity (luma) using the relative luminance formula.
  const calculateLuma = rgb => {
    const { r, g, b } = rgb
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b
    return luma
  }

  // Function to determine the contrast color based on luminosity.
  const getContrastColor = luma => (luma > 128 ? 'black' : 'white')

  // Obtain RGB values of the background color.
  const backgroundColorRgb = hexToRgb(color)

  // Calculate luminosity of the background color.
  const luma = calculateLuma(backgroundColorRgb)

  // Determine the contrast color based on luminosity.
  const contrastColor = getContrastColor(luma)

  // Return the contrast color.
  return contrastColor
}
