import React, { useState } from 'react'
import TagsInput from 'react-tagsinput'
import { TextField } from '@mui/material'
import './tagsinput.css'

function EmailInput({
  value,
  placeholder,
  error,
  fullWidth = true,
  onChange,
  ...rest
}) {
  const [emails, setEmails] = useState(value ?? [])

  const handleEmailChange = tags => {
    const uniqueTags = [...new Set(tags)]
    setEmails(uniqueTags)
    onChange(uniqueTags)
  }

  function handlePasteSplit(data) {
    // Dividir a string em um array usando vírgula, ponto e vírgula ou espaço como delimitadores.
    const emailArray = data.split(/[;, ]+/)

    // Remover espaços em branco do início e do final de cada email no array.
    return emailArray
      .filter(email => email.trim() !== '')
      .map(email => email.trim())
  }

  return (
    <div>
      <TextField
        {...rest}
        fullWidth={fullWidth}
        multiline
        error={Boolean(error)}
        helperText={error}
        slotProps={{
          input: {
            inputComponent: TagsInput,
            inputProps: {
              value: emails,
              addKeys: [13, ';', ','],
              removeKeys: [],
              addOnPaste: true,
              onChange: handleEmailChange,
              pasteSplit: handlePasteSplit,
              inputProps: {
                placeholder,
                onBlur: rest.onBlur,
              },
            },
          },

          inputLabel: { shrink: true },
        }}
      />
    </div>
  )
}

export default EmailInput
