import useSWR from 'swr'
import { useCallback, useState } from 'react'
import { merge } from 'lodash'
import { axiosInstance } from '#/store/services/AxiosInstance'

function normalizeData(payload) {
  if (payload?.data && Object.keys(payload).length === 1) {
    return payload.data
  }
  return payload
}

function normalizedParams(method, params) {
  return method === 'get' ? { params } : { data: params }
}

// Função auxiliar assíncrona para realizar requisições usando axiosInstance
export async function fetcher({
  controller,
  method = 'get',
  params,
  headers,
  axiosOptions = {},
}) {
  if (!controller) {
    console.error('Missing controller on fetcher')
    return null
  }
  const response = await axiosInstance({
    method,
    controller,
    ...normalizedParams(method, params),
    headers,
    ...axiosOptions,
  })

  if (response) {
    return {
      data: normalizeData(response.data),
      code: response.status,
    }
  }
}

// Hook customizado useFetch para realizar requisições usando useSWR
export function useFetch(props) {
  const { swrOptions, ...args } = props

  const swrDefaultOptions = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  }

  // Usa o hook useSWR para gerenciar a requisição
  const {
    data: { data, code } = {},
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(
    args.controller ? args : null,
    fetcher,
    merge(swrDefaultOptions, swrOptions),
  )

  // Retorna os dados, erros e status da requisição
  return {
    data,
    code: code ?? error?.status,
    error: error?.data?.error,
    isLoading: isLoading || isValidating,
    isValidating,
    mutate,
  }
}

// Hook customizado useMakeFetch para realizar requisições com estado local
export default function useMakeFetch() {
  // Estado local para armazenar a resposta da requisição e o status de carregamento
  const [response, setResponse] = useState({ isLoading: false })

  // Função assíncrona para fazer a requisição usando fetcher e atualizar o estado local
  const makeFetch = useCallback(async props => {
    setResponse({ isLoading: true })
    // Faz a requisição usando fetcher e atualiza o estado com os dados recebidos
    await fetcher(props)
      .then(({ data, code }) => {
        setResponse({ data, code, isLoading: false })
      })
      .catch(({ error } = {}) => {
        setResponse({ error, code: error?.status_code, isLoading: false })
      })
  }, [])

  // Retorna o estado atual da resposta e a função para fazer a requisição
  return [response, makeFetch]
}
